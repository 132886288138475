import request from '../utils/request'

const apis = {
    UnitExplain: '/api/MyLesson/GetUnitExplainById',
    GetUnitApxList: '/api/MyLesson/GetUnitApxList',
    DelReUnitApx: '/api/MyLesson/DelReUnitApx',
    AddUnitApx: '/api/MyLesson/AddUnitApx',
    SetUnitSelect: '/api/MyLesson/SetUnitSelect',
    UpdateUnit: 'api/Mylesson/UpdateUnit',
    AddUnit: 'api/NewLesson/AddUnit',
    AddLesson: 'api/NewLesson/AddLesson',
    AddSection: 'api/NewLesson/AddSection',
    AddAction: 'api/NewLesson/AddAction',
    AddActionApx: 'api/NewLesson/AddActionApx',
    AddSectionApx_new: 'api/NewLesson/AddSectionApx_new',
    LessonFiles: 'api/Mylesson/LessonFiles',
    GetLessonLstByCourse: 'api/NewLesson/GetLessonLstByCourse',
    GetUnitInfoById: 'api/NewLesson/GetUnitInfoById',
    DelMyUnit: '/api/NewLesson/DelMyUnit',
    DelMyLesson: '/api/NewLesson/DelMyLesson',
    ApplyAudit: 'api/NewLesson/ApplyAudit'
}

/**
 * 提交审核
 * @param {lessonid:'55'} params 
 */
 export function applyAudit(parameter) {
    return request({
        url: apis.ApplyAudit,
        method: 'post',
        data: parameter
    })
}


/**
 * 删除单元
 * @param {unitId:'55'} params 
 */
 export function deleteMyUnit(parameter) {
    return request({
        url: apis.DelMyUnit,
        method: 'post',
        data: parameter
    })
}


/**
 * 删除课时
 * @param {lessonId:'55'} params 
 */
 export function deleteMyLesson(parameter) {
    return request({
        url: apis.DelMyLesson,
        method: 'post',
        data: parameter
    })
}


/**
 * 依据单元ID获取课程课时信息
 * @param {unitId:'55'} params 
 */
 export function getUnitInfoById(parameter) {
    return request({
        url: apis.GetUnitInfoById,
        method: 'post',
        data: parameter
    })
}


/**
 * 依据科目获取我的课程
 * @param {course:'语文'} params 
 */
 export function getLessonLstByCourse(parameter) {
    return request({
        url: apis.GetLessonLstByCourse,
        method: 'post',
        data: parameter
    })
}


/**
 * 添加单元
 * @param {
 * HallId:展馆id号
 * UnitName:	单元名称
 * UnitType:	单元类型，取值：语文，英语，通用
 * Grade:年级,取值：1年级，2年级，3年级，4年级，5年级，6年级
 * Semester：学期，取值：1，2；  1表示上学期，2表示下学期
 * UnitBackground：单元背景
 * UnitTarget：单元目标
 * UnitMindMap：单元结构
 * LessonPlanning：课时规划
 * TeachingSuggestion：教学建议
 * UnitEvaluation：单元设计框架} parameter 
 */
export function addUnit(parameter) {
    return request({
        url: apis.AddUnit,
        method: 'post',
        data: {
            'model': JSON.stringify(parameter)
        }
    })
}

/**
 * 2.添加课程
 * @param {
 HallId：展馆id号
UnitId:单元id号
LessonName:	课程名称
TeachTime:	课时数，取值：大于0
Grade:年级，取值：1年级，2年级，3年级，4年级，5年级，6年级
Semester：学期，取值：1，2；  1表示上学期，2表示下学期
LessonType：类型；取值：单元，单课
FrontCover：封面
MindMap：教学导图
LessonTarget：课程目标
LessonDesc：课程介绍
TeachingPlan:教案
Courseware:课件
 * } parameter 
 */
export function addLesson(parameter) {
    return request({
        url: apis.AddLesson,
        method: 'post',
        data: {
            'model': JSON.stringify(parameter)
        }
    })
}

/**
 * 3.添加课时
 * @param {
 LessonId：课程id号
SectionTitle:课时标题
SectionName:	课时名称
SectionTarget:	课时数，取值：大于0
SectionDesc:年级，取值：1年级，2年级，3年级，4年级，5年级，6年级
SectionVedio2：学期，取值：1，2；  1表示上学期，2表示下学期
appendixEntities：[{  课时附件，可以是多个
AppendixType：附件类型 1课前 2课中 3课后
ApxUrl：附件链接，格式如：course/20201214/5083815051935976467.mp4
ApxFileName：附件名称
ApxTag：标签
ApxType：附件类型；取值：图片，声音，视频，文档，PPT
ApxText：文字说明
FileSize：附件大小
}]
 * } parameter 
 */
export function addSection(parameter) {
    return request({
        url: apis.AddSection,
        method: 'post',
        data: {
            'model': JSON.stringify(parameter)
        }
    })
}

/**
 * 依据单元ID获取单元解读
 * @param {unitId:'55'} parameter 
 */
export function getUnitExplainById(parameter) {
    return request({
        url: apis.UnitExplain,
        method: 'post',
        data: parameter
    })
}

/**
 * 单元解读更新
 * @param {*
 * unitid 单元id号
 * newValue  修改后的数据
 * filed   要进行修改的字段
 * filed参数值：
 * unitbackground  单元背景
 * unittarget   单元目标
 * unitmindmap   单元结构
 * lessonplanning  课时划分
 * teachingsuggestion 教学建议
 * unitevaluation  单元设计框架
 * } parameter 
 */
export function updateUnit(parameter) {
    return request({
        url: apis.UpdateUnit,
        method: 'post',
        data: parameter
    })
}

/**
 * 单元解读附件 一键下载
 * @param {*
1.id 课时id号   即：sectionid
2.downtype  下载类型
downtype参数值：
section  课时
lesson 课程
 * } parameter 
 */
export function downLoadLessonFiles(parameter) {
    return request({
        url: apis.LessonFiles,
        method: 'post',
        headers: {
            'content-type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
        responseType: 'blob',
        data: parameter
    })
}


/**
 * 获取单元附件
 * @param {model:{"unitId":81,"type":1,"del":1}} parameter 
 * 必须 unitId 单元id，type 类型 [1 单元导学|2 单元评价]，del 标记 [1可用|0已删除]
 */
export function getUnitApxList(parameter) {
    return request({
        url: apis.GetUnitApxList,
        method: 'post',
        data: parameter
    })
}

/**
 * 删除恢复附件 单元
 * @param {apxId:63,type:模式 必须 [0删除 1恢复]} parameter 
 */
export function delReUnitApx(parameter) {
    return request({
        url: apis.DelReUnitApx,
        method: 'post',
        data: parameter
    })
}

/**
 * 新增附件 单元附件[上传OSS|链接]
 * @param {model:} parameter 
 * {"unitId":82,"appendixType":1,"purpose":"思维导图","apxTag":"课后练习","OldFileName":"pic.jpg","SourceUrl":"course/20201214/4905685887391632586.png","FileType":"image/jpeg","FileSize":9101}
 */
export function addUnitApx(parameter) {
    return request({
        url: apis.AddUnitApx,
        method: 'post',
        data: parameter
    })
}

/**
 * 设置单元被选中，用于下次进入回显
 * @param {unitId:0,course:'语文'} parameter 
 */
export function setUnitSelect(parameter) {
    return request({
        url: apis.SetUnitSelect,
        method: 'post',
        data: parameter
    })
}