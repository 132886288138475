<template>
  <div class="lesson-design">
    <!-- 教学活动 -->
    <div class="top-actions">
      <a-button type="link" icon="build" @click="designCompareAction"
        >设计框架</a-button
      >
      <a-button type="link" icon="cloud-download" @click="downVisible = true"
        >一键下载</a-button
      >
      <!-- <a-popover title="" trigger="focus" placement="bottomLeft">
        <template slot="content">
          <div class="service-content">
            <img
              src="@/assets/avatar_sevicce.png"
              class="service-icon"
              alt=""
              srcset=""
            />
            <div class="service-c-r">
              <p class="tel">023-8888 8888</p>
              <p>联系学校，获取更多资源。</p>
            </div>
          </div>
        </template>
        
      </a-popover> -->
    </div>
    <a-spin :spinning="spinning">
      <div class="unit-group">
        <!-- 课时目标 -->
        <lesson-design-header
          bgColor="#EBECFC"
          :canEdit="unedit"
          :editIcon="editIcon"
          :apxType="1"
          @edit="editTarget"
        >
          <img slot="icon" src="@/assets/ksmb.png" alt="" srcset="" />
          <div slot="content">
            <a-popover
              v-model="visible"
              title="编辑课时目标"
              trigger="click"
              placement="bottomLeft"
            >
              <div slot="content" class="edit-target-content">
                <div>
                  <a-textarea
                    v-model="editSectionTarget"
                    :rows="6"
                    placeholder="请输入课时目标"
                    allow-clear
                  />
                </div>
                <br />
                <div style="text-align: right">
                  <a-button
                    type="primary"
                    :loading="saveloading"
                    @click="saveSectionTarget"
                    >保存</a-button
                  >
                </div>
              </div>
              <span style="white-space: pre-wrap">{{ sectionTarget }}</span>
            </a-popover>
          </div>
        </lesson-design-header>

        <!-- 课前 -->
        <lesson-design-header
          bgColor="#EEEEEE"
          :canEdit="unedit"
          :editIcon="addIcon"
          soltPadding="30px 15px 10px 30px"
          :isUpload="true"
          :missingList="beforeMissApx"
          @uploadFile="beforeUploadFile"
          @uploadLink="beforeUploadFile"
          @recovery="beforeRecovery"
          @permanently="beforePermanentlyDelete"
          @preview="beforeMissPreviewSource"
        >
          <img slot="icon" src="@/assets/kq.png" alt="" srcset="" />
          <div slot="content">
            <div v-for="(val, key) in beforeObj" :key="key">
              <div
                v-if="key == 'default'"
                style="display: flex; flex-wrap: wrap"
              >
                <file
                  v-for="(item, index) in val"
                  :key="item.AppendixId"
                  :modal="item"
                  :index="index"
                  :canDelete="unedit"
                  @delete="deleteBeforeApx"
                  @preview="beforePreviewSource"
                ></file>
              </div>
              <div v-else>
                <after-class-source :title="key">
                  <div slot="content" style="display: flex; flex-wrap: wrap">
                    <file
                      v-for="(item, index) in val"
                      :key="item.AppendixId"
                      :modal="item"
                      :index="index"
                      :canDelete="unedit"
                      @delete="deleteBeforeApx"
                      @preview="beforePreviewSource"
                    ></file>
                  </div>
                </after-class-source>
              </div>
            </div>
          </div>
        </lesson-design-header>

        <!-- 课中 -->
        <lesson-design-header
          :showRightBtn="true"
          :missActions="missActions"
          @resetMissAction="resetMissAction"
          soltPadding="30px 15px 10px 30px"
        >
          <img slot="icon" src="@/assets/kz.png" alt="" srcset="" />
          <div class="warp" slot="content">
            <vuedraggable class="actions" v-model="actionList" @end="onSortEnd">
              <transition-group type="transition" name="list">
                <lesson-design-action
                  v-for="(item, index) in actionList"
                  :key="item.actionId"
                  :modal="item"
                  :index="index"
                  :canEdit="unedit"
                  @actionDelete="deleteAction"
                  @toolDelete="actionToolDelete"
                  @editTarget="actionEditTarget"
                  @uploadPic="actionUploadPic"
                  @editColor="actionEditColor"
                  @editName="actionEditName"
                  @uploadFile="actionUploadFile"
                  @uploadLink="actionUploadFile"
                  @recoveryTool="actionRecoveryTool"
                  @permanently="actionPermanentlyDelTool"
                  @preview="actionPreviewSource"
                  @previewmiss="actionMissPreviewSource"
                ></lesson-design-action>
              </transition-group>
            </vuedraggable>

            <div class="create-btn">
              <a-button v-if="unedit" type="primary" @click="createAction"
                >创建</a-button
              >
            </div>

            <!-- 课中工具 -->
            <lesson-design-header
              soltPadding="30px 15px 10px 30px"
              bgColor="#EDF0F5"
              :canEdit="unedit"
              :editIcon="addIcon"
              :isUpload="true"
              :missingList="toolsMissApx"
              @uploadFile="actionToolUploadFile"
              @uploadLink="actionToolUploadFile"
              @recovery="toolRecovery"
              @permanently="toolPermanentlyDelete"
              @preview="toolsMissPreviewSource"
            >
              <img slot="icon" src="@/assets/gj.png" alt="" srcset="" />
              <div slot="content">
                <div v-for="(val, key) in midObj" :key="key">
                  <div
                    v-if="key == 'default'"
                    style="display: flex; flex-wrap: wrap"
                  >
                    <file
                      v-for="(item, index) in val"
                      :key="item.AppendixId"
                      :modal="item"
                      :index="index"
                      :canDelete="unedit"
                      @delete="deleteActionsApx"
                      @preview="actionToolPreviewSource"
                    ></file>
                  </div>
                  <div v-else>
                    <after-class-source :title="key">
                      <div
                        slot="content"
                        style="display: flex; flex-wrap: wrap"
                      >
                        <file
                          v-for="(item, index) in val"
                          :key="item.AppendixId"
                          :modal="item"
                          :index="index"
                          :canDelete="unedit"
                          @delete="deleteActionsApx"
                          @preview="actionToolPreviewSource"
                        ></file>
                      </div>
                    </after-class-source>
                  </div>
                </div>
              </div>
            </lesson-design-header>
          </div>
        </lesson-design-header>

        <!-- 课后 -->
        <lesson-design-header
          bgColor="#EEEEEE"
          :canEdit="unedit"
          :editIcon="addIcon"
          :isUpload="true"
          :missingList="afterMissApx"
          soltPadding="30px 15px 10px 30px"
          @uploadFile="afterUploadFile"
          @uploadLink="afterUploadFile"
          @recovery="afterRecovery"
          @permanently="afterPermanentlyDelete"
          @preview="afterMissPreviewSource"
        >
          <img slot="icon" src="@/assets/kh.png" alt="" srcset="" />
          <div slot="content">
            <div v-for="(val, key) in afterObj" :key="key">
              <div
                v-if="key == 'default'"
                style="display: flex; flex-wrap: wrap"
              >
                <file
                  v-for="(item, index) in val"
                  :key="item.AppendixId"
                  :modal="item"
                  :index="index"
                  :canDelete="unedit"
                  @delete="deleteAfterSourceApx"
                  @preview="afterPreviewSource"
                ></file>
              </div>
              <div v-else>
                <after-class-source :title="key">
                  <div slot="content" style="display: flex; flex-wrap: wrap">
                    <file
                      v-for="(item, index) in val"
                      :key="item.AppendixId"
                      :modal="item"
                      :index="index"
                      :canDelete="unedit"
                      @delete="deleteAfterSourceApx"
                      @preview="afterPreviewSource"
                    ></file>
                  </div>
                </after-class-source>
              </div>
            </div>
          </div>
        </lesson-design-header>
      </div>
    </a-spin>
    <img src="@/assets/gy.png" class="footer-gy" alt="" srcset="" />

    <!-- 设计思路弹窗 -->
    <a-modal
      width="100%"
      :visible="designCompare"
      :bodyStyle="{ background: 'rgba(0,0,0,0)' }"
      :footer="null"
      title=""
      centered
      @cancel="designCompareAction"
    >
      <div>
        <h4>巴蜀思路</h4>
        <idea-map :reset="designCompare" :obj="bsIdeas"></idea-map>
        <a-divider></a-divider>
        <h4>我的思路</h4>
        <idea-map
          :needRefresh="true"
          :reset="designCompare"
          :obj="info"
        ></idea-map>
        <img
          style="display: block; margin: 0 auto"
          src="@/assets/bssl.png"
          alt=""
          srcset=""
        />
      </div>
    </a-modal>

    <a-modal
      width="100%"
      :zIndex="2000"
      centered
      :visible="showPlayer"
      :footer="null"
      title=""
      @cancel="hidePlayer"
    >
      <source-player
        :previewIndex="previewIndex"
        :sources="previewSource"
        :ispreview="ispreview"
      ></source-player>
    </a-modal>
    <a-modal
      v-model="downVisible"
      title="操作确认"
      okText="确定"
      cancelText="取消"
      @ok="handleDownLoad"
    >
      <p>确认下载当前课时所有课件？</p>
    </a-modal>
  </div>
</template>

<script>
import gjkb from "@/assets/gjkb.png";
import bsjd from "@/assets/bsjd.png";
import wdjd from "@/assets/wdjd.png";
import editIcon from "@/assets/edit.png";
import addIcon from "@/assets/big-add.png";
import LessonDesignHeader from "../../../../components/LessonDesignHeader.vue";
import LessonDesignAction from "../../../../components/LessonDesignAction.vue";
import File from "../../../../components/File.vue";
import AfterClassSource from "../../../../components/AfterClassSource.vue";
import vuedraggable from "vuedraggable";
import IdeaMap from "@/components/IdeaMap.vue";

import {
  getSectionById,
  updateMySection,
  createMyAction,
  delMyAction,
  delReSectionApx,
  delReLessonApx,
  updataActionInfo,
  actionSort,
  addLessonApx,
  addSectionApx,
  missingSectionApxList,
  missingActionList,
  reAction,
  removeApx,
  getBsIdeas,
} from "@/api/user";

import { setUnitSelect, downLoadLessonFiles } from "@/api/unit";

import { fileType, getSource } from "@/utils/util";
import SourcePlayer from "../../../../components/SourcePlayer.vue";
export default {
  components: {
    LessonDesignHeader,
    LessonDesignAction,
    File,
    AfterClassSource,
    vuedraggable,
    IdeaMap,
    SourcePlayer,
  },
  data() {
    return {
      info: {},
      bsIdeas: {},
      gjkb: gjkb,
      bsjd: bsjd,
      wdjd: wdjd,
      editIcon: editIcon,
      addIcon: addIcon,
      visible: false,
      saveloading: false,
      current: 0,
      sectionTarget: "",
      editSectionTarget: "",
      beforeMissApx: [],
      beforeObj: {},
      actionList: [],
      missActions: [],
      midObj: {},
      toolsMissApx: [],
      afterObj: {},
      afterMissApx: [],
      homework: [],
      otherSource: [],
      spinning: false,
      designCompare: false,
      ispreview: false,
      showPlayer: false,
      previewSource: [],
      previewIndex: 0,
      downVisible: false,
    };
  },
  computed: {
    unedit() {
      return this.$route.meta.review != "true";
    },
  },
  mounted() {
    let courseRoute = {
      path: "/book/course/detail?course=" + this.$route.query.course,
      title: this.$route.query.course,
    };
    let route = {
      path: this.$route.fullPath,
      title: this.$route.query.title,
    };
    fileType("http:mmmasdfasdfsdf.pdf");
    this.$store.commit("M_ADD_BOOK_ROUTER", [courseRoute, route]);
    this.requestInfo();
    this.requestBeforMissApx();
    this.requestToolsMissApx();
    this.requestAfterMissApx();
    this.requestBsIdeas();
    this.requestMissActions();
    setUnitSelect({
      unitId: this.$route.query.unitId,
      course: this.$route.query.course,
    });
  },
  methods: {
    requestInfo() {
      this.spinning = true;
      getSectionById({ sectionId: this.$route.query.sectionId }).then((res) => {
        console.log(res);
        this.spinning = false;
        let sectionInfo = res.data.sectionInfo;
        this.info = sectionInfo;
        sectionInfo.lessonTimeInfo.forEach((element) => {
          if (element.name == "课中") {
            let action = element;
            this.actionList = action.actionList;
            this.midObj = this.insertToolByGroup(action.appendix);
          } else if (element.name == "课前") {
            this.beforeObj = this.insertToolByGroup(element.appendix);
          } else if (element.name == "课后") {
            let after = element.appendix || [];
            this.afterObj = this.insertToolByGroup(element.appendix);
          }
        });
        this.sectionTarget = sectionInfo.sectionTarget;
        this.editSectionTarget = sectionInfo.sectionTarget;
      });
    },
    /**
     * 请求课前历史删除文件
     */
    requestBeforMissApx() {
      missingSectionApxList({
        apxType: 1,
        sectionId: this.$route.query.sectionId,
      }).then((res) => {
        this.beforeMissApx = res.data.missingList;
      });
    },
    /**
     * 请求课中工具历史删除文件
     */
    requestToolsMissApx() {
      missingSectionApxList({
        apxType: 2,
        sectionId: this.$route.query.sectionId,
      }).then((res) => {
        this.toolsMissApx = res.data.missingList;
      });
    },
    /**
     * 请求课后历史删除文件
     */
    requestAfterMissApx() {
      missingSectionApxList({
        apxType: 3,
        sectionId: this.$route.query.sectionId,
      }).then((res) => {
        this.afterMissApx = res.data.missingList;
      });
    },
    /**
     * 获取删除的模块
     */
    requestMissActions() {
      missingActionList({ sectionId: this.$route.query.sectionId }).then(
        (res) => {
          console.log(res);
          this.missActions = res.data.missingList;
        }
      );
    },
    /**
     * 获取巴蜀思路
     */
    requestBsIdeas() {
      getBsIdeas({ sectionId: this.$route.query.sectionId }).then((res) => {
        if (res.code == 200) {
          let obj = {
            lessonTimeInfo: [],
          };
          obj.lessonTimeInfo = res.data.lessonTimeList;
          this.bsIdeas = obj;
        }
      });
    },
    /**
     * 工具分组
     */
    insertToolByGroup(list) {
      let obj = {
        default: [],
      };
      list.forEach((item) => {
        if (item.ApxTag && item.ApxTag.length > 0) {
          obj[item.ApxTag] = obj[item.ApxTag] || [];
          obj[item.ApxTag].push(item);
        } else {
          obj.default.push(item);
        }
      });
      return obj;
    },
    hidePlayer() {
      this.ispreview = false;
      this.showPlayer = false;
    },
    /**
     * 排序课中模块
     */
    onSortEnd(el) {
      let json = this.actionList.map((item, index) => {
        return {
          aid: item.actionId,
          sort: index + 1,
        };
      });
      for (let index = 0; index < this.info.lessonTimeInfo.length; index++) {
        const element = this.info.lessonTimeInfo[index];
        if (element.name == "课中") {
          element.actionList = this.actionList;
          break;
        }
      }
      this.info = { ...this.info };
      actionSort({ model: JSON.stringify(json) }).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.msg);
        }
      });
    },
    /**
     * 修改课时目标
     */
    editTarget() {
      this.visible = true;
    },
    /**
     * 保存修改的课时目标
     */
    saveSectionTarget() {
      if (this.editSectionTarget.length == 0) {
        this.visible = false;
        return;
      }
      this.saveloading = true;
      updateMySection({
        sectionId: this.$route.query.sectionId,
        target: this.editSectionTarget,
      }).then((res) => {
        if (res.code == 200) {
          this.sectionTarget = this.editSectionTarget;
          this.saveloading = false;
          this.visible = false;
        }
      });
    },
    /**
     * 删除课前工具
     */
    deleteBeforeApx(params) {
      this.spinning = true;
      delReSectionApx({ apxId: params.AppendixId, type: 0 }).then((res) => {
        this.spinning = false;
        if (res.code == 200) {
          this.$message.success(res.msg);
          if (params.ApxTag.length > 0) {
            this.beforeObj[params.ApxTag].splice(params.index, 1);
          } else {
            this.beforeObj["default"].splice(params.index, 1);
          }
          this.beforeMissApx.push(params);
        }
      });
    },
    /**
     * 恢复课前工具
     */
    beforeRecovery(params) {
      delReSectionApx({ apxId: params.AppendixId, type: 1 }).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.msg);
          if (params.ApxTag.length == 0) {
            this.beforeObj.default.push(params);
          } else {
            if (this.beforeObj[params.ApxTag]) {
              this.beforeObj[params.ApxTag].push(params);
            } else {
              this.$set(this.beforeObj, params.ApxTag, [params]);
            }
          }
          this.beforeMissApx.splice(params.index, 1);
        }
      });
    },
    /**
     * 永久删除课前工具
     */
    beforePermanentlyDelete(params) {
      removeApx({ apxId: params.AppendixId, type: 1 }).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.msg);
          this.beforeMissApx.splice(params.index, 1);
        }
      });
    },
    /**
     * 课前新增工具
     */
    beforeUploadFile(params) {
      params.sectionId = this.$route.query.sectionId;
      params.appendixType = 1;
      addSectionApx({ model: JSON.stringify(params) }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.$message.success(res.msg);
          if (params.ApxTag.length == 0) {
            this.beforeObj.default.push(res.data.newAppendix);
          } else {
            if (this.beforeObj[params.ApxTag]) {
              this.beforeObj[params.ApxTag].push(res.data.newAppendix);
            } else {
              this.$set(this.beforeObj, params.ApxTag, [res.data.newAppendix]);
            }
          }
        }
      });
    },
    /**
     * 删除课后资源
     */
    deleteAfterSourceApx(params) {
      this.spinning = true;
      delReSectionApx({ apxId: params.AppendixId }).then((res) => {
        this.spinning = false;
        if (res.code == 200) {
          this.$message.success(res.msg);
          if (params.ApxTag.length > 0) {
            this.afterObj[params.ApxTag].splice(params.index, 1);
          } else {
            this.afterObj["default"].splice(params.index, 1);
          }
          this.afterMissApx.push(params);
        }
      });
    },
    /**
     * 课后工具恢复
     */
    afterRecovery(params) {
      delReSectionApx({ apxId: params.AppendixId, type: 1 }).then((res) => {
        this.spinning = false;
        if (res.code == 200) {
          this.$message.success(res.msg);
          if (params.ApxTag.length == 0) {
            this.afterObj.default.push(params);
          } else {
            if (this.afterObj[params.ApxTag]) {
              this.afterObj[params.ApxTag].push(params);
            } else {
              this.$set(this.afterObj, params.ApxTag, [params]);
            }
          }
          this.afterMissApx.splice(params.index, 1);
        }
      });
    },
    /**
     * 永久删除课后工具
     */
    afterPermanentlyDelete(params) {
      delAliYunFile({ apxId: params.AppendixId, type: 1 }).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.msg);
          this.afterMissApx.splice(params.index, 1);
        }
      });
    },
    /**
     * 新增课后资源文件
     */
    afterUploadFile(params) {
      params.sectionId = this.$route.query.sectionId;
      params.appendixType = 3;
      addSectionApx({ model: JSON.stringify(params) }).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.msg);
          if (params.ApxTag.length == 0) {
            this.afterObj.default.push(res.data.newAppendix);
          } else {
            if (this.afterObj[params.ApxTag]) {
              this.afterObj[params.ApxTag].push(res.data.newAppendix);
            } else {
              this.$set(this.afterObj, params.ApxTag, [res.data.newAppendix]);
            }
          }
        }
      });
    },
    /**
     * 删除课中工具
     */
    deleteActionsApx(params) {
      this.spinning = true;
      delReSectionApx({ apxId: params.AppendixId }).then((res) => {
        this.spinning = false;
        if (res.code == 200) {
          this.$message.success(res.msg);
          if (params.ApxTag.length > 0) {
            this.midObj[params.ApxTag].splice(params.index, 1);
          } else {
            this.midObj["default"].splice(params.index, 1);
          }
          this.toolsMissApx.push(params);
        }
      });
    },
    /**
     * 课中工具恢复
     */
    toolRecovery(params) {
      delReSectionApx({ apxId: params.AppendixId, type: 1 }).then((res) => {
        this.spinning = false;
        if (res.code == 200) {
          this.$message.success(res.msg);
          if (params.ApxTag.length == 0) {
            this.midObj.default.push(params);
          } else {
            if (this.midObj[params.ApxTag]) {
              this.midObj[params.ApxTag].push(params);
            } else {
              this.$set(this.midObj, params.ApxTag, [params]);
            }
          }
          this.toolsMissApx.splice(params.index, 1);
        }
      });
    },
    /**
     * 永久删除课中工具
     */
    toolPermanentlyDelete(params) {
      delAliYunFile({ apxId: params.AppendixId, type: 1 }).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.msg);
          this.toolsMissApx.splice(params.index, 1);
        }
      });
    },
    /**
     * 课中工具新增文件
     */
    actionToolUploadFile(params) {
      params.sectionId = this.$route.query.sectionId;
      params.appendixType = 2;
      addSectionApx({ model: JSON.stringify(params) }).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.msg);
          if (params.ApxTag.length == 0) {
            this.midObj.default.push(res.data.newAppendix);
          } else {
            if (this.midObj[params.ApxTag]) {
              this.midObj[params.ApxTag].push(res.data.newAppendix);
            } else {
              this.$set(this.midObj, params.ApxTag, [res.data.newAppendix]);
            }
          }
        }
      });
    },

    /***
     * 编辑课中模块的目标
     */
    actionEditTarget(params) {
      updataActionInfo(params).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.msg);
          this.actionList[params.index].actionTarget = params.newValue;
        }
      });
    },
    /**
     * 课中模块上传封面
     */
    actionUploadPic(params) {
      this.actionList[params.index].actionFrontCover = params.base64;
      let { actionId, url } = params;
      updataActionInfo({
        actionId: actionId,
        newValue: url,
        filed: "cover",
      }).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.msg);
        }
      });
    },
    /**
     * 修改模块的颜色
     */
    actionEditColor(params) {
      updataActionInfo(params).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.msg);
          this.actionList[params.index].bgColor = params.newValue;
        }
      });
    },
    /**
     * 修改模块的名称
     */
    actionEditName(params) {
      updataActionInfo(params).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.msg);
          this.actionList[params.index].actionName = params.newValue;
        }
      });
    },
    /**
     * 模块上传文件
     */
    actionUploadFile(params) {
      let str = JSON.stringify(params);
      addLessonApx({ model: str }).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.msg);
          this.actionList[params.index].appendix.push(res.data.newAppendix);
        }
      });
    },
    /**
     * 创建一个新的模块
     */
    createAction() {
      createMyAction({ sectionId: this.$route.query.sectionId }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.actionList.push(res.data.newAction);
        }
      });
    },
    /**
     * 删除一个模块
     */
    deleteAction(item) {
      delMyAction(item).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.msg);
          this.actionList.splice(item.index, 1);
          let action = {
            ActionId: item.actionId,
            ActionName: item.actionName,
          };
          this.missActions.push(action);
        }
      });
    },
    /**
     * 从历史中恢复一个模块
     */
    resetMissAction(params) {
      reAction({ actionId: params.ActionId }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.$message.success(res.msg);
          this.missActions.splice(params.index, 1);
          this.actionList.push(res.data.action);
        }
      });
    },
    /**
     * 删除一个模块中的工具
     */
    actionToolDelete(params) {
      delReLessonApx({ apxId: params.AppendixId, type: 0 }).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.msg);
          let action = this.actionList[params.pindex];
          let apxs = action.appendix;
          let missApxs = action.missingAppendix;
          apxs.splice(params.index, 1);
          missApxs.push(params);
        }
      });
    },
    /**
     * 恢复课中一个action中的工具
     */
    actionRecoveryTool(params) {
      delReLessonApx({ apxId: params.AppendixId, type: 1 }).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.msg);
          let action = this.actionList[params.pindex];
          let apxs = action.appendix;
          let missApxs = action.missingAppendix;
          missApxs.splice(params.index, 1);
          apxs.push(params);
        }
      });
    },
    /**
     * 永久删除课中模块中action的文件
     */
    actionPermanentlyDelTool(params) {
      delAliYunFile({ apxId: params.AppendixId, type: 2 }).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.msg);
          let action = this.actionList[params.pindex];
          let missApxs = action.missingAppendix;
          missApxs.splice(params.index, 1);
        }
      });
    },
    /**
     * 课中模块资源预览
     */
    actionPreviewSource(params) {
      this.ispreview = true;
      this.previewSource = this.actionList[params.pindex].appendix;
      this.previewIndex = params.index;
      this.showPlayer = true;
    },
    actionMissPreviewSource(params) {
      this.ispreview = true;
      this.previewSource = this.actionList[params.pindex].missingAppendix;
      this.previewIndex = params.index;
      this.showPlayer = true;
    },
    /**
     * 课前已删除的文件预览
     */
    beforeMissPreviewSource(params) {
      this.ispreview = true;
      this.previewSource = this.beforeMissApx;
      this.previewIndex = params.index;
      this.showPlayer = true;
    },
    /**
     * 课中工具文件预览
     */
    actionToolPreviewSource(params) {
      this.ispreview = true;
      if (params.ApxTag == "") {
        params.ApxTag = "default";
      }
      this.previewSource = this.midObj[params.ApxTag];
      this.previewIndex = params.index;
      this.showPlayer = true;
    },
    /**
     * 预览课中工具删除的文件
     */
    toolsMissPreviewSource(params) {
      this.ispreview = true;
      this.previewSource = this.toolsMissApx;
      this.previewIndex = params.index;
      this.showPlayer = true;
    },
    /**
     * 预览课后删除的文件
     */
    afterMissPreviewSource(params) {
      this.ispreview = true;
      this.previewSource = this.afterMissApx;
      this.previewIndex = params.index;
      this.showPlayer = true;
    },
    /**
     * 预览课前工具
     */
    beforePreviewSource(params) {
      this.ispreview = true;
      if (params.ApxTag == "") {
        params.ApxTag = "default";
      }
      this.previewSource = this.beforeObj[params.ApxTag];
      this.previewIndex = params.index;
      this.showPlayer = true;
    },
    /**
     * 预览课后工具
     */
    afterPreviewSource(params) {
      this.ispreview = true;
      if (params.ApxTag == "") {
        params.ApxTag = "default";
      }
      this.previewSource = this.afterObj[params.ApxTag];
      this.previewIndex = params.index;
      this.showPlayer = true;
    },
    /**
     * 设计框架对吧
     */
    designCompareAction() {
      this.designCompare = !this.designCompare;
    },
    /**
     * 一键下载
     */
    handleDownLoad() {
      this.downVisible = false;
      window.location.href = `http://yun.cqcdbs.cn/api/Mylesson/LessonFiles?id=${this.$route.query.sectionId}&downtype=section`;
    },
  },
};
</script>

<style lang="less">
.edit-target-content {
  width: 1000px;
}
.lesson-design {
  padding: 30px 10px 24px 118px;

  .create-btn {
    display: flex;
    justify-content: center;
  }
  .top-actions {
    padding-right: 20px;
    display: flex;
    justify-content: flex-end;
  }
  .unit-group {
    padding-top: 30px;
    .actions {
      padding-top: 40px;
      margin-bottom: 30px;
      span {
        display: flex;
        flex-wrap: wrap;
      }
      .list-move {
        transition: transform 0.5s;
      }
      .no-move {
        transition: transform 0s;
      }
    }
    padding-bottom: 40px;
  }
  .footer-gy {
    width: 1140px;
    height: 48px;
    display: block;
    margin: 0 auto;
  }
}
</style>